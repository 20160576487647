import React from "react"
import Home from "../components/Pages/Home"
import { useLanguage } from "../hooks"

const IndexPage = () => {
  const { language } = useLanguage("pt")
  return (
    <Home title="FLYNOW" titlePage="Home"  pageId="home" showEbooks={language==='pt'} showCourses={language==='pt'} description='O combo mais que perfeito para o seu desenvolvimento pessoal! Seja mais produtivo, crie e mantenha novos hábitos, estabeleça metas alcançáveis, descomplique suas finanças pessoais e se mantenha motivado no processo. Tudo isso com os nossos apps! Me diz se não é o combo perfeito para você! Nossos apps: Flynow - Produtividade, Hábitos & Metas; Flynow - Finanças Pessoais; Flynow - Frases de Motivação & Sabedoria; Aplicativo para produtividade, hábitos, metas, finanças pessoais e motivação. ' />
  )
}

export default IndexPage
